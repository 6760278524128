import React, { useState, useEffect, useRef } from "react"
import { Button, Row, Col, Input, Space, message, Spin, Modal, Select } from "antd"

import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { throttle } from "lodash"
import {
  syncOrder,
  getOrderStatus,
  syncFreight,
  syncOrgs,
  syncSupplier,
  syncCustomer,
  syncCategory,
  syncWsym,
  syncTenantInfo,
  syncSupplierInfo,
} from "./service"

const INFO = {
  1: "订单",
  2: "货单",
  3: "组织",
  4: "供应商",
  5: "客户",
  6: "品类",
  7: "网上有名账户",
  8: "租户名称同步见证宝",
  9: "供应商名称同步见证宝",
}
/**
 * 同步新再生网订单
 * @returns
 */
function UtilOrder() {
  const [orderIds, setOrderIds] = useState("")
  const [supplierIds, setSupplierIds] = useState("")
  const [supplierIdsList, setSupplierIdsList] = useState<string[]>([])
  const [orderIdsList, setOrderIdsList] = useState<string[]>([])
  const [type, setType] = useState("")
  const [orgName, setOrgName] = useState("")
  const [orgsName, setOrgsName] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [orderInfo, setOrderInfo] = useState<{
    count: number
    successNumber: number
    failNumber: number
    failMsg: string
  }>()

  /**
   * 订单号
   * @param e
   */
  const entryOrderIdHandle = (e) => {
    setOrderIds(e.target.value)
  }
  useEffect(() => {
    if (orderIds?.length) {
      setOrderIdsList(
        orderIds
          .replace(/ /, "")
          .replace(/\，/g, ",")
          .split(",")
          .filter((item) => item)
      )
    }
    if (supplierIds?.length) {
      setSupplierIdsList(
        supplierIds
          .replace(/ /, "")
          .replace(/\，/g, ",")
          .split(",")
          .filter((item) => item)
      )
    }
  }, [orderIds, supplierIds])
  /**
   * 获取同步订单状态
   */
  const getResStatus = async () => {
    try {
      const res: any = await getOrderStatus()

      if (!res?.data?.complete) {
        let timer = setTimeout(() => {
          getResStatus()
          clearTimeout(timer)
        }, 1000)
      } else {
        setOrderIds("")
        setOrderIdsList([])
        setDisabled(false)
        setOrderInfo(res?.data)
        setIsModalOpen(true)
      }
    } catch (e) {
      setDisabled(false)
    }
  }
  const submit = async () => {
    if (disabled) return
    try {
      if (!orderIdsList?.length) {
        message.error("请输入")
        return
      }
      if (type === "1") {
        const res: any = await syncOrder(orderIdsList)
        setDisabled(true)
        getResStatus()
      }
      if (type === "2") {
        const res: any = await syncFreight(orderIdsList)
        if (res?.code === 200) {
          if (res.data.failNumber) {
            setOrderIds("")
            setOrderIdsList([])
            setDisabled(false)
            setOrderInfo(res?.data)
            setIsModalOpen(true)
          } else {
            setOrderIds("")
            setOrderIdsList([])
            setDisabled(false)
            message.success("同步成功")
          }
        } else {
          message.error(res?.message)
        }
      }
      // 同步网上有名账户
      if (type === "7") {
        const res: any = await syncWsym(orderIdsList)
        setOrderIds("")
        setOrderIdsList([])
        setDisabled(false)
        message.info(res.message)
      }
      if (type === "8") {
        const res: any = await syncTenantInfo(orderIdsList)
        setOrderIds("")
        setOrderIdsList([])
        setDisabled(false)
        message.info(res.message)
      }
      if (type === "9") {
        const res: any = await syncSupplierInfo(orderIdsList)
        setOrderIds("")
        setOrderIdsList([])
        setDisabled(false)
        message.info(res.message)
      }
    } catch (e) {
      setDisabled(false)
    }
  }
  // 同步组织
  const syncOrgHandle = async () => {
    if (!orgName) {
      message.error("请输入组织名称")
      return
    }
    const res: any = await syncOrgs(orgName)
    if (res.code === 200) {
      setOrgName("")
      message.success("同步成功")
    } else {
      message.error(res.message)
    }
  }
  // 同步品类
  const syncCategoryHandle = async () => {
    if (!orgName) {
      message.error("请输入组织名称")
      return
    }
    const res: any = await syncCategory(orgName)
    if (res.code === 200) {
      message.success("同步成功")
      setOrgName("")
    } else {
      message.error(res.message)
    }
  }
  // 同步供应商/ 客户
  const syncSuppHandle = async () => {
    if (!supplierIdsList?.length) {
      message.error(`请输入${INFO[type]}名称`)
      return
    }
    let res: any
    if (type === "4") {
      res = await syncSupplier({
        tenantName: orgsName,
        supplierNames: supplierIdsList,
      })
    }
    if (type === "5") {
      res = await syncCustomer({
        tenantName: orgsName,
        customerNames: supplierIdsList,
      })
    }
    if (res.code === 200) {
      setOrderIdsList([])
      setSupplierIds("")
      message.success("同步成功")
    } else {
      message.error(res.message)
    }
  }
  return (
    <DetailPageContainer>
      <Modal
        title="订单同步"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <div style={{ padding: 20 }}>
          <p>
            同步{orderInfo?.count}条，成功{orderInfo?.successNumber}条，失败{orderInfo?.failNumber}
          </p>

          <p>{orderInfo?.failMsg}</p>
        </div>
      </Modal>
      <DetailPageHeader
        title={
          <Space>
            <span>同步新再生网订单</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "同步新再生网订单", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        {disabled && (
          <div
            style={{
              zIndex: 1000,
              height: "100vh",
              width: "100vw",
              position: "fixed",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        <Row>
          <Col>
            <Select
              style={{ width: 306 }}
              onChange={(value) => {
                setType(value)
                setOrgName("")
              }}
              placeholder="请选择同步类型"
              options={[
                { label: "同步订单", value: "1" },
                { label: "同步货单", value: "2" },
                { label: "同步组织", value: "3" },
                { label: "同步供应商", value: "4" },
                { label: "同步客户", value: "5" },
                { label: "同步品类", value: "6" },
                { label: "同步网上有名", value: "7" },
                { label: "同步见证宝（租户）", value: "8" },
                { label: "同步见证宝（供应商）", value: "9" },
              ]}
            />
          </Col>
        </Row>

        {type === "1" || type === "2" || type === "7" || type === "8" || type === "9" ? (
          <>
            <textarea
              placeholder={`请输入${INFO[type]}号，多个${INFO[type]}号请用逗号隔开`}
              style={{ width: 300, height: 200 }}
              value={orderIds}
              onChange={entryOrderIdHandle}
            />
            <ul>
              {orderIdsList &&
                orderIdsList.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
            </ul>
            {orderIdsList.length ? <h4>共匹配（{orderIdsList.length}）</h4> : null}
            <Button style={{ width: 140 }} type="primary" onClick={submit}>
              同步{INFO[type]}
            </Button>
          </>
        ) : null}

        {type === "3" || type === "6" ? (
          <>
            <Row>
              <Col>
                <Input
                  style={{ width: 306 }}
                  value={orgName}
                  placeholder="请输入组织名称"
                  onChange={(e) => setOrgName(e.target.value)}
                ></Input>
              </Col>
            </Row>

            <Button
              style={{ width: 140 }}
              type="primary"
              onClick={() => {
                if (type === "3") {
                  syncOrgHandle()
                }
                if (type === "6") {
                  syncCategoryHandle()
                }
              }}
            >
              同步{INFO[type]}
            </Button>
          </>
        ) : null}
        {type === "4" || type === "5" ? (
          <>
            <Row>
              <Col>
                <Input
                  style={{ width: 306 }}
                  value={orgsName}
                  placeholder="请输入组织名称"
                  onChange={(e) => setOrgsName(e.target.value)}
                ></Input>
              </Col>
            </Row>
            <Row>
              <Col>
                <textarea
                  placeholder={`请输入${INFO[type]}号，多个${INFO[type]}号请用逗号隔开`}
                  style={{ width: 300, height: 200 }}
                  value={supplierIds}
                  onChange={(e) => setSupplierIds(e.target.value)}
                />
              </Col>
            </Row>
            <Button style={{ width: 140 }} type="primary" onClick={syncSuppHandle}>
              同步{INFO[type]}
            </Button>
          </>
        ) : null}
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default UtilOrder
