/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Button, message, Row, Col, Form, Input, Radio, Select } from "antd"
import { OperableImage } from "@src/components/ImageOptions"
import { cross_env } from "@src/services/serviceOp"
import { getOcrBusinessLicense, getOrgs } from "../../../../service"
import { OrgTypesEnum } from "../../constants"
const FormItem = Form.Item
const colProps = {
  span: 12,
}
const BusinessLicense = (props) => {
  const { form, setLicenseImage, licenseImage, list, originalOrgs } = props
  const [formationDisabled, setFormationDisabled] = useState<any>(true)
  const [orgsList, setOrgsList] = useState<any>([]) // 组织列表

  const init = async () => {
    setOrgsList(originalOrgs)
  }

  const handleSearchOrg = async (e) => {
    let result: any = []
    // 超过4个汉字搜索
    if (e?.length > 0) {
      let searchValue = e.replaceAll("有限", "").replaceAll("公司", "")
      const res = await getOrgs({
        searchCount: true,
        size: 9999,
        current: 1,
        keywords: searchValue,
      })
      if (res.code == 200) {
        result = res.data.records.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })
      }
    } else {
      result = originalOrgs
    }

    setOrgsList(result)
  }

  useEffect(() => {
    init()
  }, [])

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  console.log(form.getFieldsValue(), 612313)
  return (
    <>
      <Row>
        <Col {...colProps}>
          <Form.Item
            label="营业执照"
            name="businessLicense"
            rules={[{ required: true, message: "请上传营业执照", max: 100 }]}
          >
            <div style={{ display: "flex" }}>
              <OperableImage
                width={120}
                height={120}
                action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
                src={licenseImage[0]?.downloadLink}
                uploadFileProps={{
                  accept: "image/jpg,image/jpeg,image/png",
                  name: "file",
                  onChange: async ({ file }) => {
                    if (file.response?.data?.downloadLink) {
                      setLicenseImage([file.response?.data])
                      form.setFieldValue("businessLicense", file.response?.data.filePath)
                      message.loading("营业执照信息识别中...", 1.5)
                      const result: any = await getOcrBusinessLicense({
                        url: file.response?.data?.downloadLink,
                      })
                      if (result.code == 200) {
                        console.log(result, "result")
                        form.setFieldsValue({
                          name: result?.data?.name,
                          idNo: result?.data?.regNum,
                          operatorLegal: result?.data?.person,
                          businessAddress: result?.data?.address,
                        })
                        setFormationDisabled(false)
                      } else {
                        setFormationDisabled(true)
                        message.error(result?.response?.data?.msg || "识别失败")
                      }
                    }
                  },
                }}
              >
                营业执照
              </OperableImage>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item label="公司类型" name="type">
            <Select disabled={true} options={OrgTypesEnum} style={{ width: 380 }}></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item
            label="主体名称"
            name="name"
            rules={[{ required: list?.needName }, { message: "请输入主体名称" }]}
          >
            <Input placeholder={"请输入"} style={{ width: 380 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item
            label="统一社会信用代码"
            name="idNo"
            rules={[{ required: true }, { pattern: /^92.{16}$/, message: "请输入以92开头，18位" }]}
          >
            <Input placeholder={"请输入"} style={{ width: 380 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item
            label="经营者"
            name="operatorLegal"
            rules={[{ required: true }, { message: "请输入经营者" }]}
          >
            <Input placeholder={"请输入"} style={{ width: 380 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item
            label="经营地址"
            name="businessAddress"
            rules={[{ required: true }, { message: "请输入经营地址" }]}
          >
            <Input placeholder={"请输入"} style={{ width: 380 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item label="申请编号" name="applyNo">
            <Input disabled={true} placeholder={"申请编号"} style={{ width: 380 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item label="所属税区" name="regionName">
            <Input disabled={true} placeholder={"所属税区"} style={{ width: 380 }} />
          </Form.Item>
        </Col>
        <Col span={0}>
          <Form.Item label="所属税区id" name="taxRegionId">
            <Input disabled={true} placeholder={"所属税区id"} style={{ width: 380 }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item label="组织名称" name="linkCustomerId">
            <Select
              style={{ width: 380 }}
              placeholder="请输入关键词查询"
              allowClear
              showSearch
              onSearch={handleSearchOrg}
              filterOption={filterOption}
              onClear={() => setOrgsList([])}
              options={orgsList}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default BusinessLicense
