import { OP_LOGISTIC, OP_PUB_DATA, OP_RESOURCE, OP_SOURCE } from "../../api/config/servicePort"
import UtilOp from "../../services/serviceOp"
const utilOp = UtilOp.getInstance()

//云仓列表
export const getWarehouseList = (params?: any) => {
  return utilOp.httpGet(OP_LOGISTIC + `/logistic/warehouse`, params)
}
//地址经度转换
export const getWarehouseLatLon = (params?: any) => {
  return utilOp.httpGet(OP_RESOURCE + "/map/geocoder", params)
}
//经度转换地址
export const getWarehouseAddress = (params?: any) => {
  return utilOp.httpGet(OP_RESOURCE + "/map/address", params)
}
//云仓新增
export const getWarehouseAdd = (params?: any) => {
  return utilOp.httpPost(OP_LOGISTIC + `/logistic/warehouse`, params)
}
//云仓更新
export const getWarehouseUpdate = (params?: any) => {
  return utilOp.httpPut(OP_LOGISTIC + `/logistic/warehouse`, params)
}
//云仓删除
export const getWarehouseDelete = (id?: any) => {
  return utilOp.httpDeletel(OP_LOGISTIC + `/logistic/warehouse/${id}`)
}
//云仓详情
export const getWarehouseDetails = (id?: any) => {
  return utilOp.httpGet(OP_LOGISTIC + `/logistic/warehouse/${id}`)
}
// 查询省市区
export const getTissueCityTreeList = (params: any) => {
  return utilOp.httpGet(OP_PUB_DATA + "/city/tree", params)
}
// 根据文件id获取，文件链接
export const getUrlByFileId = (params: { zoom: boolean; fileIds: string }) => {
  return utilOp
    .httpGet(`${OP_SOURCE}/files/ossUrlZoom`, params)
    .then((res) => res as { data: string[]; code: number })
}
