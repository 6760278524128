/* eslint-disable no-debugger */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { Navigate, useLocation, useNavigate, useRoutes } from "react-router-dom"
import { Error, Login } from "../pages"

//平台设置
import PlatformSetup from "@src/pages/PlatformSetup"
import PlatformSetupApply from "@src/pages/PlatformSetup/Apply"
import PlatformSetupApplyDetail from "@src/pages/PlatformSetup/Apply/Detail"
import PlatformInvoicefrom from "@src/pages/PlatformSetup/Invoice"
import PlatformUtilOrder from "@src/pages/PlatformSetup/UtilOrder"
import PlatformSetupTaxDistrict from "@src/pages/PlatformSetup/TaxDistrict"
import NewTaxDistrictDetails from "@src/pages/PlatformSetup/TaxDistrict/Details"
import NewTaxDistrict from "@src/pages/PlatformSetup/TaxDistrict/NewTaxDistrict"
import PlatformSetupTissue from "@src/pages/PlatformSetup/Tissue"
import PlatformSetupUserDetail from "@src/pages/PlatformSetup/User/UserModal/Detail"
import PlatformSetupUser from "@src/pages/PlatformSetup/User/index"
import PlatformSetupCheck from "@src/pages/PlatformSetup/Check"

// 实体交易
import Entity from "@src/pages/Entity"

// 订单
import ProcureOrder from "@/pages/Entity/orderManagement/ProcureOrder"
import Classification from "@/pages/Entity/platformClassification"
import NewPurchaseOrder from "@src/pages/Entity/orderManagement/ProcureOrder/detail"

import PlatformSetupTissueCreate from "@src/pages/PlatformSetup/Tissue/create"
import PlatformSetupTissueInfo from "@src/pages/PlatformSetup/Tissue/info"

import DataCharts from "@src/pages/DataCharts"
import Charts from "@src/pages/DataCharts/Charts"
import LargeScreen from "@src/pages/DataCharts/LargeScreen"

//业务代办
import StoreDetail from "../pages/BusinessAgent/StoreDetail/index"
import BusinessAgent from "../pages/BusinessAgent/index"
import OutSelFEmpLoyedDetails from "../pages/BusinessAgent/outSelfEmployed/details/index"
import OutSelFEmpLoyed from "../pages/BusinessAgent/outSelfEmployed/index"
import OutNewLogout from "../pages/BusinessAgent/outSelfEmployed/newLogout/index"
import ModifyInformation from "../pages/BusinessAgent/selfEmployed/details/components/modifyInformation"
import ApplicationDetails from "../pages/BusinessAgent/selfEmployed/details/index"
import SelFEmpLoyed from "../pages/BusinessAgent/selfEmployed/index"
import NewApplication from "../pages/BusinessAgent/selfEmployed/newApplication"

//交易方案
import TradingSchemeDetail from "@src/pages/Entity/TradingScheme/Details/index"
import TradingSchemeNewADd from "@src/pages/Entity/TradingScheme/NewTradingScheme/index"
import TradingScheme from "@src/pages/Entity/TradingScheme/index"

import ContractTemplateAdd from "@src/pages/Entity/contractTemplate/create"
import ContractTemplateDetail from "@src/pages/Entity/contractTemplate/detail"
import ContractTemplate from "@src/pages/Entity/contractTemplate/index"
//税票系统
import InvoicingParty from "@src/pages/ReceiptSystem/pages/Ticket/InvoicingParty"
import Ledger from "@src/pages/ReceiptSystem/pages/ledger/ledger"
import OpenTask from "@src/pages/ReceiptSystem/pages/openTask/openTask"
import Ticket from "../pages/ReceiptSystem/index"
import InvoicingTask from "../pages/ReceiptSystem/pages/InvoicingTask"
import InvoicingTaskInfo from "../pages/ReceiptSystem/pages/InvoicingTask/InvoceInfo"
// 存证平台
import Evidence from "../pages/Evidence"
import Fields from "../pages/Evidence/pages/Fields"
import AddFeild from "../pages/Evidence/pages/Fields/pages/Add"
import FieldList from "../pages/Evidence/pages/Fields/pages/FieldList"
import AddEvi from "../pages/Evidence/pages/Management/pages/AddEvidence"
import ManagementInfo from "../pages/Evidence/pages/Management/pages/Info"
import Template from "../pages/Evidence/pages/Template"
import AddTemplate from "../pages/Evidence/pages/Template/pages/AddTemplate"
//物联管理
import Internet from "@src/pages/Internet/index"
import SuperVisory from "@src/pages/Internet/supervisory/index"
//视频监控大屏
import VideoSurveillance from "../pages/Cockpit/VideoSurveillance/index"

//供应链金融
import CoreEnterpriseDetails from "../pages/SupplyFinance/coreEnterprise/details/index"
import CoreEnterprise from "../pages/SupplyFinance/coreEnterprise/index"
import FinancingOrderDetails from "../pages/SupplyFinance/financingOrder/details/index"
import FinancingOrder from "../pages/SupplyFinance/financingOrder/index"
import FinancingProductDetails from "../pages/SupplyFinance/financingProduct/details/index"
import FinancingProduct from "../pages/SupplyFinance/financingProduct/index"
import SupplyFinance from "../pages/SupplyFinance/index"
import SupplierAccessDetails from "../pages/SupplyFinance/supplierAccess/details/index"
import SupplierAccess from "../pages/SupplyFinance/supplierAccess/index"

// 物流管理
import Logistics from "../pages/logistics/index"
import Trajectory from "../pages/logistics/Trajectory/index"
import Warehouse from "../pages/logistics/warehouse/index"
export const routeData: Array<any> = [
  {
    path: "/",
    element: <Login />,
    meta: {
      key: "login",
    },
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      key: "login",
    },
  },
  {
    path: "/video/surveillance",
    element: <VideoSurveillance />,
    meta: {
      key: "videoSurveillance",
    },
  },
  {
    path: "*",
    element: <Error />,
    meta: {
      key: "error",
    },
  },
  {
    path: "/",
    // redirect:'/entity'
    element: <Navigate to="/entity/transaction"></Navigate>,
    meta: {
      key: "redirect",
    },
  },
  // 数据统计
  {
    path: "/data",
    element: <DataCharts />,
    children: [
      // 数据看版
      {
        path: "/data/chart",
        element: <Charts></Charts>,
      },
      {
        path: "/data/large",
        element: <LargeScreen></LargeScreen>,
      },
    ],
  },
  // 店铺详情
  {
    path: "/agencyBusiness/storeDetail/:id",
    element: <StoreDetail />,
  },
  // 实体交易
  {
    path: "/entity/transaction",
    element: <Entity></Entity>,
    children: [
      // 采购订单
      {
        path: "/entity/transaction/procureOrder",
        element: <ProcureOrder></ProcureOrder>,
      },
      {
        path: "/entity/transaction/procureOrder/:id",
        element: <NewPurchaseOrder />,
      },

      // 平台品类
      {
        path: "/entity/transaction/platformClassification",
        element: <Classification></Classification>,
      },
      // 交易方案
      {
        path: "/entity/transaction/tradingScheme",
        element: <TradingScheme></TradingScheme>,
      },
      // 交易方案新建
      {
        path: "/entity/transaction/TradingSchemeDetail/:id",
        element: <TradingSchemeNewADd></TradingSchemeNewADd>,
      },
      // 交易方案详情
      {
        path: "/entity/transaction/tradingScheme/:id",
        element: <TradingSchemeDetail></TradingSchemeDetail>,
      },
      // 合同模板管理
      {
        path: "/entity/transaction/contractTemplate",
        element: <ContractTemplate></ContractTemplate>,
      },
      // 合同模板管理新建
      {
        path: "/entity/transaction/contractTemplate/:type",
        element: <ContractTemplateAdd></ContractTemplateAdd>,
      },
      // 合同模板管理编辑
      {
        path: "/entity/transaction/contractTemplate/:type/:id",
        element: <ContractTemplateAdd></ContractTemplateAdd>,
      },
      // 合同模板管理详情
      {
        path: "/entity/transaction/contractTemplate/detail/:id",
        element: <ContractTemplateDetail></ContractTemplateDetail>,
      },
    ],
  },
  {
    path: "/platformSetup",
    element: <PlatformSetup />,
    children: [
      {
        path: "/platformSetup/user",
        element: <PlatformSetupUser />,
        meta: {
          key: "system_user",
        },
      },
      {
        path: "/platformSetup/Check",
        element: <PlatformSetupCheck />,
        meta: {
          key: "system_check",
        },
      },
      {
        path: "/platformSetup/user/:id",
        element: <PlatformSetupUserDetail />,
      },
      {
        path: "/platformSetup/apply",
        element: <PlatformSetupApply />,
        meta: {
          key: "system_apply",
        },
      },
      {
        path: "/platformSetup/apply/:id/:type",
        element: <PlatformSetupApplyDetail />,
      },
      {
        path: "/platformSetup/tissue",
        element: <PlatformSetupTissue />,
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/tissue/create/:applyNo",
        element: <PlatformSetupTissueCreate />,
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/tissue/info/:id",
        element: <PlatformSetupTissueInfo />,
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/district",
        element: <PlatformSetupTaxDistrict />,
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/district/create/:id",
        element: <NewTaxDistrict />,
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/district/:id",
        element: <NewTaxDistrictDetails />,
        meta: {
          key: "system_tissue",
        },
      },
      {
        path: "/platformSetup/invoice",
        element: <PlatformInvoicefrom />,
        meta: {
          key: "system_invoice",
        },
      },
      {
        path: "/platformSetup/utilOrder",
        element: <PlatformUtilOrder />,
        meta: {
          key: "system_utilOrder",
        },
      },
    ],
  },
  // 供应链金融
  {
    path: "/finance",
    element: <SupplyFinance></SupplyFinance>,
    children: [
      // 个体户开办
      {
        path: "/finance/order",
        element: <FinancingOrder />,
      },
      {
        path: "/finance/order/:id",
        element: <FinancingOrderDetails />,
      },
      {
        path: "/finance/enterprise",
        element: <CoreEnterprise />,
      },
      {
        path: "/finance/enterprise/:id",
        element: <CoreEnterpriseDetails />,
      },
      {
        path: "/finance/consent",
        element: <SupplierAccess />,
      },
      {
        path: "/finance/consent/:id",
        element: <SupplierAccessDetails />,
      },
      {
        path: "/finance/product",
        element: <FinancingProduct />,
      },
      {
        path: "/finance/product/:id",
        element: <FinancingProductDetails />,
      },
    ],
  },
  // 业务代办
  {
    path: "/agencyBusiness",
    element: <BusinessAgent></BusinessAgent>,
    children: [
      // 个体户开办
      {
        path: "/agencyBusiness/setup",
        element: <SelFEmpLoyed />,
      },
      {
        path: "/agencyBusiness/setup/newApplication/:id",
        element: <NewApplication />,
      },
      {
        path: "/agencyBusiness/setup/:id",
        element: <ApplicationDetails />,
      },
      {
        path: "/agencyBusiness/ModifyInformation/:id",
        element: <ModifyInformation />,
      },
      {
        path: "/agencyBusiness/outSetup",
        element: <OutSelFEmpLoyed />,
      },
      {
        path: "/agencyBusiness/outSetup/:id",
        element: <OutSelFEmpLoyedDetails />,
      },
      {
        path: "/agencyBusiness/outNewLogout",
        element: <OutNewLogout />,
      },
    ],
  },
  //开票系统
  {
    path: "/ticket",
    element: <Ticket />,
    children: [
      {
        path: "/ticket/invoicingParty",
        element: <InvoicingParty></InvoicingParty>,
      },
      {
        path: "/ticket/InvoicingTask",
        element: <InvoicingTask></InvoicingTask>,
      },
      {
        path: "/ticket/InvoicingTask/info/:id",
        element: <InvoicingTaskInfo />,
      },
      {
        path: "/ticket/openTask",
        element: <OpenTask />,
      },
      {
        path: "/ticket/ledger",
        element: <Ledger />,
      },
    ],
  },
  // 存证平台
  {
    path: "/evidence",
    element: <Evidence />,
    children: [
      // 模板列表
      {
        path: "/evidence/platform/template",
        element: <Template />,
      },
      // 添加模版
      {
        path: "/evidence/platform/template/add",
        element: <AddTemplate />,
      },
      // 客户管理详情
      {
        path: "/evidence/platform/management/manage_info",
        element: <ManagementInfo />,
        exact: true,
      },
      // 字段列表
      {
        path: "/evidence/platform/field",
        element: <Fields />,
        meta: {
          key: "fields",
        },
      },
      // 字段模板创建和编辑
      {
        path: "/evidence/platform/field/add",
        element: <AddFeild />,
      },
      // 存证场景关联字段信息
      {
        path: "/evidence/platform/field/list",
        element: <FieldList />,
      },
      //   //客户关联存证
      {
        path: "/evidence/platform/add_evidence",
        element: <AddEvi />,
      },
    ],
  },
  //物联管理
  {
    path: "/internet",
    element: <Internet />,
    children: [
      {
        path: "/internet",
        element: <SuperVisory></SuperVisory>,
      },
    ],
  },
  //物联管理
  {
    path: "/logistics",
    element: <Logistics />,
    children: [
      {
        path: "/logistics/warehouse",
        element: <Warehouse></Warehouse>,
      },
      {
        path: "/logistics/trajectory",
        element: <Trajectory></Trajectory>,
      },
    ],
  },
]

const Element = () => {
  const loca = JSON.parse(localStorage.getItem("operation_authorization") || "{}")?.access_token_op
  const navigate = useNavigate()
  const routerInfo = useLocation().pathname.split("/")[2]
  // 独立页面免登录
  useEffect(() => {
    if (!loca && routerInfo != "storeDetail") {
      navigate("/login")
    }
  }, [loca])
  if (!loca && routerInfo != "storeDetail") {
    const arr = [
      {
        path: "/login",
        element: <Login />,
        meta: {
          key: "login",
        },
      },
    ]
    const loginRoutes = useRoutes(arr)
    return loginRoutes
  } else {
    const routes = useRoutes(routeData)
    return routes
  }
}
export default Element
