/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-key */
import { ProColumns, ProTable } from "@ant-design/pro-table"
import Access from "@src/components/Access"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { useAccess } from "@src/contents/useAccess"
import { Button, Space, message } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CustomRangePicker } from "../../../components/CustomRangePicker"
import { useQueryParamsStorage } from "../../../hooks/useQueryParamsStorage"
import { getRangeDateOnUnitOfTime } from "../../../utils/date/utils"
import exportToExcel from "../../../utils/exportToExcel"
import {
  OrgTypeAuthStatusEnum,
  OrgTypeEnum,
  OrgTypeOutStatusList,
  OrgTypeStatusEnum,
  OrgTypeStatusList,
} from "./constant"
import "./index.scss"
import { getOrganizationsList, getTissueList, getRegionsList, getUserLists } from "./promises"
const PlatformSetupTissue = () => {
  const [totalCount, setTotalCount] = useState<number>(0)
  const access = useAccess()
  const [list, setList] = useState([])
  const [getTaxMin, setGetTaxMin] = useState<any>([])

  const navigate = useNavigate()
  //获取办税人 运营端角色
  const handGetPlatCharactersList = async (data?: any) => {
    let params = { size: -1, applicationId: 1, roleNames: "办税人", current: 1 }
    const result: any = await getUserLists(params)
    if ((result.code = 200)) {
      const res = result.data.records.map((ie: any) => {
        return [ie.id, { text: ie.name }]
      })
      console.log(new Map(res), "res")
      setGetTaxMin(new Map(res))
    } else {
      message.error("请求失败")
    }
  }
  const columns: ProColumns<Tissue.Record>[] = [
    {
      title: "组织名称",
      dataIndex: "name",
      fixed: "left",
      search: false,
    },
    {
      title: "证件号",
      dataIndex: "idNo",
      search: false,
    },
    {
      title: "组织类型",
      dataIndex: "types",
      valueType: "select",
      valueEnum: OrgTypeEnum,
      hideInTable: true,
    },
    {
      title: "组织类型",
      dataIndex: "type",
      valueEnum: OrgTypeEnum,
      search: false,
    },
    {
      title: "所属税区",
      dataIndex: "taxRegistrationName",
      search: false,
    },
    {
      title: "办税人",
      dataIndex: "invoiceClerk",
      valueType: "text",
      valueEnum: getTaxMin,
      onFilter: true,
      fieldProps: {
        allowClear: true, // 允许清除选项
        showSearch: true, // 显示搜索框
      },
      // hideInTable: true,
    },
    {
      title: "认证状态 ",
      dataIndex: "authStatus",
      valueType: "select",
      valueEnum: OrgTypeAuthStatusEnum,
    },
    {
      title: "状态",
      dataIndex: "status",
      valueType: "select",
      valueEnum: OrgTypeStatusEnum,
      render: (text: any, record: any) => {
        return record?.cancelStatus == "NORMAL"
          ? OrgTypeStatusList[record.status]
          : OrgTypeOutStatusList[record.cancelStatus]
      },
    },
    {
      title: "创建日期",
      dataIndex: "createTime",
      valueType: "dateRange",
      renderFormItem: () => {
        return <CustomRangePicker />
      },
      hideInTable: true,
      search: {
        transform: (value) => {
          return {
            ...getRangeDateOnUnitOfTime({
              createTimeStart: value?.[0],
              createTimeEnd: value?.[1],
            }),
          }
        },
      },
    },
    {
      title: "创建日期",
      dataIndex: "createTime",
      valueType: "dateTime",
      search: false,
    },
    {
      title: "操作",
      valueType: "option",
      key: "option",
      width: 60,
      fixed: "right",
      render: (text, record) => (
        // <Access accessible={access.op_platform_tissue_feature_detail}>
        <a onClick={() => navigate(`/platformSetup/tissue/info/${record?.id}`)}>详情</a>
        // </Access>
      ),
    },
    {
      title: "税区",
      dataIndex: "taxRegistrationId",
      valueType: "select",
      hideInTable: true,
      request: async () => {
        const response = await getRegionsList({
          size: 200, // 获取所有数据
        })
        let taxRegionsList = []
        response.data?.records?.map((item) => {
          taxRegionsList.push({
            label: item.name,
            value: item.id.toString(),
            key: item.id.toString(),
          })
        })
        return taxRegionsList
      },
    },
    {
      title: "搜索",
      dataIndex: "keywords",
      valueType: "text",
      key: "keywords",
      hideInTable: true,
      fieldProps: {
        placeholder: "请输入组织名称证件号搜索",
      },
    },
  ]
  const actionRef = useRef()
  const formRef = useRef()
  const queryParamsStorage = useQueryParamsStorage()
  useEffect(() => {
    handGetPlatCharactersList()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>组织管理</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "组织管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <ProTable
          columns={columns}
          actionRef={actionRef}
          formRef={formRef}
          form={{
            onValuesChange: (values) => {
              return formRef?.current?.submit()
            },
            initialValues: {
              ...queryParamsStorage.queryParams,
              createTime: queryParamsStorage.queryParams?.createTimeStart
                ? [
                    queryParamsStorage.queryParams?.createTimeStart,
                    queryParamsStorage.queryParams?.createTimeEnd,
                  ]
                : undefined,
            },
          }}
          scroll={{ x: "max-content" }}
          request={async (params, sort, filter) => {
            const { pageSize, keywords, ...rest } = params
            const response = await getOrganizationsList({
              size: pageSize,
              searchCount: true,
              keywords: keywords?.trim(),
              ...rest,
              // "sorts[0].field": "createTime",
              // "sorts[0].direction": "DESCENDING",
            })
            console.log(params, sort, filter)
            setTotalCount(response.data.total)
            setList(response.data.records)
            queryParamsStorage.saveQueryParams({ ...params, pageSize: 20 })
            return {
              data: response.data.records,
              success: Boolean(response.data.records),
              total: response.data.total,
            }
          }}
          rowKey="id"
          search={{
            labelWidth: "auto",
            optionRender: false,
            collapsed: false,
          }}
          bordered={false}
          options={false}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          dateFormatter="string"
          headerTitle={`组织列表/${totalCount}`}
          toolBarRender={() => [
            <Access accessible={access.op_platform_tissue_feature_add}>
              <Button
                key="button"
                onClick={() => navigate(`/platformSetup/tissue/create/${null}`)}
                type="primary"
              >
                创建组织
              </Button>
            </Access>,
            <Access accessible={access.op_platform_tissue_feature_view}>
              <Button onClick={() => exportToExcel(list, "企业组织", columns)}>导出</Button>
            </Access>,
          ]}
        />
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default PlatformSetupTissue
