export const handleData = (start: any[]) => {
  const arr: any = start.map((item) => {
    if (item?.children?.length) {
      return {
        value: item.name,
        label: item.name,
        children: handleData(item.children),
      }
    } else {
      return {
        value: item.name,
        label: item.name,
        children: [],
      }
    }
  })
  return arr
}
export const handleDataList = (start: any[]) => {
  const arr: any = start.map((item) => {
    if (item?.children?.length) {
      return {
        value: item.code,
        label: item.name,
        children: handleDataList(item.children),
      }
    } else {
      return {
        value: item.code,
        label: item.name,
        children: [],
      }
    }
  })
  return arr
}
export const statusEnum: any = {
  true: {
    status: "success",
    text: "已认证",
  },
  false: {
    status: "error",
    text: "未认证",
  },
}
