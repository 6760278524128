/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { OperableImage } from "@src/components/OperableImage"
import { RexIdNo, RexSocialCreditCode } from "@src/constant/RegExp"
import {
  Button,
  Card,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  message,
} from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ApplicationManagement from "../components/applicationManagement"
import { handleData, taxRegistrationStatusEnum } from "../constant"
import { usePlatformSetupTissue } from "../hooks/usePlatformSetupTissue"
import { createTissue, getTissueCityTreeList, getUserLists } from "../promises"

import "./index.scss"
import { RexIndividualCode } from "../../../../constant/RegExp"
const PlatformSetupTissueCreate = () => {
  const tissueCreateService = usePlatformSetupTissue()
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  }
  const history: any = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [applyNoFlag, setApplyNoFlag] = useState<any>(false)
  const [declarationCycle, setDeclarationCycle] = useState<string>("")
  const [getTaxMin, setGetTaxMin] = useState<any>([])
  const [cityTreeList, setCityTreeList] = useState<any>([]) //省市区

  console.log(tissueCreateService, "tissueCreateService")
  useEffect(() => {
    if (history?.applyNo !== "null") {
      //1.2.10 逻辑废弃
      // let platformSetupSession = JSON.parse(sessionStorage.getItem("CREATE") || "{}")
      // form.setFieldsValue({
      //   applyNo: history?.applyNo,
      //   legalIdNo: platformSetupSession.IDNo,
      //   contact: platformSetupSession.phone,
      //   operatorLegal: platformSetupSession.name,
      // })
      // setApplyNoFlag(true)
    } else {
      setApplyNoFlag(false)
    }
  }, [history])
  useEffect(() => {
    if (tissueCreateService?.resultOcrPhotos.code == 200) {
      form.setFieldsValue({
        name: tissueCreateService?.resultOcrPhotos?.data?.name,
        idNo:
          tissueCreateService?.resultOcrPhotos?.data?.regNum ||
          tissueCreateService?.resultOcrPhotos?.data?.idNum,
        operatorLegal: tissueCreateService?.resultOcrPhotos?.data?.person,
      })
    }
    handBankTree()
    handGetPlatCharactersList()
  }, [tissueCreateService?.resultOcrPhotos])
  const handBankTree = async () => {
    const [resultCityTreeList] = await Promise.allSettled([getTissueCityTreeList({})])

    if (resultCityTreeList.status == "fulfilled") {
      setCityTreeList(handleData(resultCityTreeList?.value?.data))
    }
  }
  //获取办税人 运营端角色
  const handGetPlatCharactersList = async (data?: any) => {
    let params = { size: -1, applicationId: 1, roleNames: "办税人", current: 1 }
    const result: any = await getUserLists(params)
    if ((result.code = 200)) {
      const res = result?.data?.records?.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      setGetTaxMin(res)
    } else {
      message.error("请求失败")
    }
  }

  //办税人/开票员
  const handTaxMan = useMemo(() => {
    return (
      <>
        {declarationCycle == "REGISTERED" ? (
          <Col span={8}>
            <Form.Item name="invoiceClerk" label="办税人/开票员">
              <Select
                placeholder="请选择"
                style={{ maxWidth: 420 }}
                onChange={(e) => {
                  console.log(e)
                }}
                options={getTaxMin}
              />
            </Form.Item>
          </Col>
        ) : null}
      </>
    )
  }, [getTaxMin, declarationCycle])
  //省市区
  const handResultCityTreeList = useMemo(() => {
    return (
      <>
        <Col span={8}>
          <Form.Item name="provinceName" hidden></Form.Item>
          <Form.Item name="cityName" hidden></Form.Item>
          <Form.Item name="areaName" hidden></Form.Item>
          <Form.Item label="选择省市区" name="bankCityTissue" rules={[{ required: true }]}>
            <Cascader
              style={{ maxWidth: 420 }}
              options={cityTreeList}
              placeholder="请选择省市区"
              allowClear
              onChange={(values, options) => {
                if (options?.length > 0) {
                  form.setFieldsValue({
                    provinceName: options[0]?.label,
                    cityName: options[1]?.label,
                    areaName: options[2].label,
                  })
                }
              }}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="detailAddress"
            label="详细地址"
            rules={[{ required: true }, { message: "请填写详细地址" }]}
          >
            <Input placeholder="请输入详细地址" />
          </Form.Item>
        </Col>
      </>
    )
  }, [cityTreeList, declarationCycle])
  //申报周期
  const hanDeclarationCycle = useMemo(() => {
    return (
      <>
        {declarationCycle == "REGISTERED" ? (
          <Col span={8}>
            <Form.Item name="declarationCycle" label="申报周期" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value="MONTH"> 按月申报 </Radio>
                <Radio value="QUARTER"> 按季度申报 </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        ) : null}
      </>
    )
  }, [declarationCycle])
  const YingyezhizhaoAB = () => (
    <>
      <Form.Item label="上传营业执照" rules={[{ required: true }]}>
        <OperableImage
          filename="上传营业执照"
          width={239}
          height={162}
          classFileName={"businessLicense"}
          uploadFileProps={{
            onChange: tissueCreateService.onUploadFileChange.bind(null, "businessLicense"),
          }}
          src={
            tissueCreateService?.codePhotos.find((item) => item.fileType === "businessLicense")
              ?.fileUrl
          }
        >
          上传营业执照
        </OperableImage>
      </Form.Item>
      <Row style={{ paddingTop: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="name"
            label={form.getFieldValue("type") == "INDIVIDUAL" ? "姓名" : "组织名称"}
            rules={[{ required: true }]}
          >
            <Input
              maxLength={30}
              style={{ maxWidth: 420 }}
              placeholder="自动识别，请仔细核对信息"
              disabled={tissueCreateService?.resultOcrPhotosFlag}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="idNo"
            label="统一社会信用代码"
            rules={[
              { required: true },
              {
                message: "请填写正确格式",
                pattern:
                  form.getFieldValue("type") == "INDIVIDUAL_BUSINESS"
                    ? RexIndividualCode
                    : RexSocialCreditCode,
              },
            ]}
          >
            <Input
              style={{ maxWidth: 420 }}
              placeholder="自动识别，请仔细核对信息"
              disabled={tissueCreateService?.resultOcrPhotosFlag}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="operatorLegal"
            label={form.getFieldValue("type") == "ENTERPRISE" ? "法人" : "经营者"}
            rules={[{ required: true }]}
          >
            <Input
              style={{ maxWidth: 420 }}
              placeholder="自动识别，请仔细核对信息"
              disabled={tissueCreateService?.resultOcrPhotosFlag}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ paddingTop: "24px" }}>
        {form.getFieldValue("type") == "INDIVIDUAL_BUSINESS" ? (
          <Col span={8}>
            <Form.Item name="idCardNo" label="经营者身份证" rules={[{ required: true }]}>
              <Input style={{ maxWidth: 420 }} placeholder="请输入" />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={8}>
          <Form.Item
            name="contact"
            label="联系电话"
            rules={[{ required: true }, { message: "请填写联系电话" }]}
          >
            <Input style={{ maxWidth: 420 }} placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="applyNo" label="申请编号" rules={[{ required: false }]}>
            <Input disabled={applyNoFlag} style={{ maxWidth: 420 }} placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingTop: "24px" }}>{handResultCityTreeList}</Row>
      <Row style={{ paddingTop: "24px" }}>
        <Col
          span={8}
          style={{ display: form.getFieldValue("type") == "INDIVIDUAL_BUSINESS" ? "" : "none" }}
        >
          {form.getFieldValue("type") == "INDIVIDUAL_BUSINESS" ? (
            <Form.Item
              name="taxRegistrationStatus"
              label="税务登记状态"
              rules={[{ required: true }]}
              initialValue={"UNREGISTERED"}
            >
              <Select
                defaultValue="UNREGISTERED"
                placeholder="请选择"
                style={{ maxWidth: 420 }}
                onChange={(e) => {
                  setDeclarationCycle(e)
                }}
                options={taxRegistrationStatusEnum}
              />
            </Form.Item>
          ) : null}
        </Col>
        {form.getFieldValue("type") == "INDIVIDUAL_BUSINESS" ? handTaxMan : ""}
        {form.getFieldValue("type") == "INDIVIDUAL_BUSINESS" ? hanDeclarationCycle : ""}
      </Row>
    </>
  )

  const YingyezhizhaoC = () => (
    <>
      <Form.Item label="身份证" rules={[{ required: true }]}>
        <Space>
          <Form.Item name="idCardFront">
            <OperableImage
              filename="身份证正面"
              width={178}
              height={121}
              tailor={true}
              classFileName={"idCardFront"}
              uploadFileProps={{
                onChange: tissueCreateService.onUploadFileChange.bind(null, "idCardFront"),
              }}
              src={
                tissueCreateService?.codePhotos.find((item) => item.fileType === "idCardFront")
                  ?.fileUrl
              }
            >
              身份证正面
            </OperableImage>
          </Form.Item>
          <Form.Item name="idCardBack" style={{ paddingLeft: 20 }}>
            <OperableImage
              filename="身份证反面"
              width={178}
              height={121}
              tailor={true}
              classFileName={"idCardBack"}
              uploadFileProps={{
                onChange: tissueCreateService.onUploadFileChange.bind(null, "idCardBack"),
              }}
              src={
                tissueCreateService?.codePhotos.find((item) => item.fileType === "idCardBack")
                  ?.fileUrl
              }
            >
              身份证反面
            </OperableImage>
          </Form.Item>
        </Space>
      </Form.Item>
      <Row style={{ paddingTop: "24px" }}>
        <Col span={8}>
          <Form.Item
            name="name"
            label={form.getFieldValue("type") == "INDIVIDUAL" ? "姓名" : "组织名称"}
            rules={[{ required: true }]}
          >
            <Input
              maxLength={30}
              placeholder="自动识别，请仔细核对信息"
              style={{ maxWidth: 420 }}
              disabled={tissueCreateService?.resultOcrPhotosFlag}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="idNo"
            label="身份证号"
            rules={[{ required: true }, { message: "请填写正确格式", pattern: RexIdNo }]}
          >
            <Input
              placeholder="自动识别，请仔细核对信息"
              style={{ maxWidth: 420 }}
              disabled={tissueCreateService?.resultOcrPhotosFlag}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="contact"
            label="联系电话"
            rules={[{ required: true }, { message: "请填写联系电话" }]}
          >
            <Input style={{ maxWidth: 420 }} placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingTop: "24px" }}>{handResultCityTreeList}</Row>
    </>
  )
  const YingyezhizhaoD = () => (
    <>
      <Row style={{ paddingTop: "16px" }}>
        <Col span={8}>
          <Form.Item
            name="name"
            label={form.getFieldValue("type") == "INDIVIDUAL" ? "姓名" : "组织名称"}
            rules={[{ required: true }]}
          >
            <Input maxLength={30} placeholder="请输入" style={{ maxWidth: 420 }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="idNo" label="唯一识别号" rules={[{ required: true }]}>
            <Input maxLength={30} placeholder="请输入" style={{ maxWidth: 420 }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="operatorLegal" label="管理员姓名">
            <Input style={{ maxWidth: 420 }} placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ paddingTop: "24px" }}>
        {handResultCityTreeList}
        <Col span={8}>
          <Form.Item
            name="contact"
            label="联系电话"
            rules={[{ required: true }, { message: "请填写联系电话" }]}
          >
            <Input style={{ maxWidth: 420 }} placeholder="请输入" />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
  const onFinish = async (values: Tissue.Info) => {
    const { idNo, ...rest } = values
    console.log(values, "values")
    const resultObject = tissueCreateService.codePhotos.reduce((acc, photo) => {
      acc[photo.fileType] = photo.fileId
      return acc
    }, {})
    let result: any = await createTissue({
      ...rest,
      legalIdNo: values.idCardNo,
      buyerOrderAuto: values?.buyerOrderAuto ? values?.buyerOrderAuto[0] : false,
      sellerOrderAuto: values?.sellerOrderAuto ? values?.sellerOrderAuto[0] : false,
      inboundInventoryAuto: values?.inboundInventoryAuto ? values?.inboundInventoryAuto[0] : false,
      outboundInventoryAuto: values?.outboundInventoryAuto
        ? values?.outboundInventoryAuto[0]
        : false,
      invoiceClerk: rest?.invoiceClerk ? [rest?.invoiceClerk] : null,
      provinceCode: rest?.bankCityTissue[0],
      cityCode: rest?.bankCityTissue[1],
      areaCode: rest?.bankCityTissue[2],
      ...resultObject,
      applicationIds: appData,
      idNo: idNo.trim(),
      name: values?.name?.trim(),
      operatorLegal: values?.operatorLegal?.trim(),
      idCardNo: values?.idCardNo?.trim(),
      contact: values?.contact?.trim(),
    })
    if (result?.code == 200) {
      message.success("成功新增组织")
      navigate(`/platformSetup/tissue`)
    }
  }
  const [appData, setAppData] = useState()
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  return (
    <>
      <DetailPageContainer>
        <DetailPageHeader
          breadCrumb={[{ label: "平台设置" }, { label: "组织管理" }, { label: "创建组织" }]}
          title="创建组织"
        />
        <DetailPageContent>
          <Form<Tissue.Info>
            form={form}
            colon={false}
            className="create-tissue"
            onFinish={onFinish}
            initialValues={{
              authStatus: "true",
              type: "INDIVIDUAL_BUSINESS",
              interconnectionConfig: "UNAPPROVAL",
              declarationCycle: "MONTH",
              paymentApplyConfig: "AUTO",
              operationAuth: {
                operationId: [
                  JSON.parse(
                    localStorage.getItem("operation_tissue") || "{}"
                  )?.organizationId?.toString(),
                ], //组织id,
                operationAuthType: "ORGANIZATION",
              },
            }}
          >
            <Card title="基本信息">
              <Form.Item name={["operationAuth", "operationAuthType"]} hidden={true}></Form.Item>
              <Form.Item
                name={["operationAuth", "operationId"]}
                label="运营方"
                rules={[{ required: true, message: "请选择" }]}
              >
                <Select
                  mode="multiple"
                  placeholder="选择运营方"
                  style={{ width: "264px" }}
                  showSearch
                  maxTagCount={6}
                  onChange={(e) => {
                    form.setFieldValue("taxRegistrationId", "")
                    tissueCreateService.taxRegionsListInit({
                      operationIds: e,
                    })
                  }}
                  filterOption={filterOption}
                  options={tissueCreateService.tissueAuthList}
                />
              </Form.Item>
              <Form.Item name="taxRegistrationId" label="所属税区">
                <Select
                  placeholder="请选择"
                  options={tissueCreateService.taxRegionsList}
                  style={{ maxWidth: 420 }}
                />
              </Form.Item>
              <Form.Item name="authStatus" label="认证状态" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="true"> 认证 </Radio>
                  <Radio value="false"> 非认证 </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="type" label="组织类型" rules={[{ required: true }]}>
                <Radio.Group
                  onChange={() => {
                    tissueCreateService.setCodePhotos([])
                    tissueCreateService.setResultOcrPhotos([])
                    tissueCreateService.setResultOcrPhotosFlag(true)
                    form.setFieldsValue({ name: "", idNo: "", operatorLegal: "" })
                  }}
                  disabled={applyNoFlag}
                >
                  <Radio value="INDIVIDUAL_BUSINESS"> 个体户 </Radio>
                  <Radio value="ENTERPRISE"> 企业 </Radio>
                  <Radio value="INDIVIDUAL"> 自然人 </Radio>
                  <Radio value="OTHER"> 其他 </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="interconnectionConfig" label="互联配置" rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value="UNAPPROVAL"> 不需要审核 </Radio>
                  <Radio value="APPROVAL"> 需要审核 </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}
              >
                {({ getFieldValue }) =>
                  getFieldValue("type") == "ENTERPRISE" ||
                  getFieldValue("type") == "INDIVIDUAL_BUSINESS" ? (
                    <YingyezhizhaoAB />
                  ) : getFieldValue("type") == "INDIVIDUAL" ? (
                    <YingyezhizhaoC />
                  ) : getFieldValue("type") == "OTHER" ? (
                    <YingyezhizhaoD />
                  ) : null
                }
              </Form.Item>
            </Card>
            {/* <Card title="银行信息" style={{ marginTop: 16 }}>
              <ApplicationBank form={form} />
            </Card> */}
            <Card title="开票信息" style={{ marginTop: 16 }}>
              <Row>
                <Col span={8}>
                  <Form.Item label="开票行名称" name="invoiceBankName">
                    <Input placeholder={"请输入"} style={{ maxWidth: 420 }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="开票行账号" name="invoiceBankAccount">
                    <Input placeholder={"请输入"} style={{ maxWidth: 420 }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="开票行预留电话" name="invoicePhone">
                    <Input placeholder={"请输入"} style={{ maxWidth: 420 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ paddingTop: "16px" }}>
                <Col span={8}>
                  <Form.Item label="开票地址" name="invoiceAddress">
                    <Input placeholder={"请输入"} style={{ maxWidth: 420 }} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title="应用管理" style={{ marginTop: 16 }}>
              <ApplicationManagement setAppData={setAppData} appData={appData} />
            </Card>
            <Card title="订单审核配置" style={{ marginTop: 16 }}>
              <div style={{ display: "flex" }}>
                <Form.Item name="buyerOrderAuto" label="自动审核">
                  <Checkbox.Group style={{ width: "100%" }}>
                    <Checkbox value={true}>作为购方订单自动审核通过</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item name="sellerOrderAuto">
                  <Checkbox.Group style={{ width: "100%" }}>
                    <Checkbox value={true}>作为销方订单自动审核通过</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item name="inboundInventoryAuto">
                  <Checkbox.Group style={{ width: "100%" }}>
                    <Checkbox value={true}>入库单自动审核通过</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item name="outboundInventoryAuto">
                  <Checkbox.Group style={{ width: "100%" }}>
                    <Checkbox value={true}>出库单自动审核通过</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </Card>
            <Card title="付款申请配置" style={{ marginTop: 16 }}>
              <Form.Item name="paymentApplyConfig" label="付款申请审批">
                <Radio.Group>
                  <Radio value="AUTO"> 自动审批通过 </Radio>
                  <Radio value="EASY"> 简易审批 </Radio>
                </Radio.Group>
              </Form.Item>
            </Card>
            <Card style={{ marginTop: 16, height: 72 }}>
              <Form.Item wrapperCol={{ offset: 10 }} style={{ marginTop: -5 }}>
                <Button
                  onClick={() => navigate(`/platformSetup/tissue`)}
                  style={{ marginRight: 8 }}
                >
                  取消
                </Button>
                <Button htmlType="submit" type="primary">
                  提交
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </DetailPageContent>
      </DetailPageContainer>
    </>
  )
}
export default PlatformSetupTissueCreate
