import React from "react"
import { Cascader } from "antd"
import type { DefaultOptionType } from "antd/es/cascader"

interface IProps {
  options: any[]
  placeholder: string
  value: any
  onChange: Function
  allowClear: boolean
  showSearch: boolean
  tooltips: any
  changeOnSelect?: any
}
/**
 * 级联选择器自定义组件
 * @param props
 * @returns
 */
const XZCascader: React.FC<IProps> = (props) => {
  const {
    options = [],
    placeholder = "请输入",
    value,
    onChange,
    allowClear = true,
    changeOnSelect = false,
  } = props
  const change = (e: any): any => {
    onChange(e)
  }

  const filter = (inputValue: string, path: any) =>
    path.some(
      (option: { label: string }) =>
        (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )

  return (
    <Cascader
      changeOnSelect
      value={value}
      style={{ width: "308px" }}
      options={options}
      placeholder={placeholder}
      onChange={change}
      onSearch={(value) => console.log(value)}
      showSearch={{ filter }}
      allowClear={allowClear}
    />
  )
}

export default XZCascader
