const withAllowClear = {
  allowClear: true,
}
export default {
  type: "object",
  labelWidth: 76,
  properties: {
    keyword: {
      title: "搜索",
      type: "string",
      widget: "input",
      placeholder: "名称",
      props: withAllowClear,
    },
    address: {
      title: "地址",
      type: "string",
      widget: "XZCascader",
      placeholder: "请选择",
      props: withAllowClear,
    },
    auth: {
      title: "认证状态",
      type: "string",
      widget: "select",
      enum: [true, false],
      enumNames: ["已认证", "未认证"],
      placeholder: "请选择",
      props: withAllowClear,
    },
  },
}
