/* eslint-disable prettier/prettier */
import React, { useRef, forwardRef, useImperativeHandle, useEffect } from "react"
import TableRender, { TableRenderProps } from "table-render"
import QuerySelect from "../QuerySelect"
import InputAndSelect from "../InputAndSelect"
import XZDateRange from "../XZDateRange"
import XZInput from "../XZInput"
import XZCascader from "../XZCascader"
// import SetColumns from "@/components/SetColumns";
import { SchemaBase } from "form-render"
import { isSaveKeys, saveKey } from "./content"
import XZExport from "@/components/XZExport"
import { downConfig } from "./exportConfig"
import dayjs from "dayjs"
import { trim } from "lodash"

type IProps<T extends Object> = {
  search: {
    schema: Partial<SchemaBase>
  }
  request: any
  searchFlag: Boolean
} & TableRenderProps<T>
/**
 * 基于table-render表单
 * @param props
 * @returns
 */
let reqData: any = {}

const SKTable = <T extends Object>(props: IProps<T>, ref: any) => {
  const tableRef: any = useRef(null)
  useImperativeHandle(ref, () => ({
    upData: () => {
      tableRef.current.refresh({ stay: true })
    },
    setSearch: (key: string, params: any) => {
      tableRef.current.form.setSchemaByPath(key, params)
    },
  }))
  useEffect(() => {
    const localReq = sessionStorage.getItem(saveKey())
    if (localReq && isSaveKeys()) {
      const localReqObj = JSON.parse(localReq)
      // 刷新页面时获取session数据后转换。
      tableRef.current.form.setValues(localReqObj)
    }
  }, [])
  const request = (req: any) => {
    reqData = req
    return props?.request(req)
  }
  const afterSearchHandle = () => {
    if (reqData?.pageSize) {
      localStorage.setItem("PAGE_SIZE", reqData.pageSize)
    }
    if (isSaveKeys()) {
      sessionStorage.setItem(saveKey(), JSON.stringify(reqData))
    }
  }
  return (
    <TableRender
      ref={tableRef}
      expandable={{ showExpandColumn: false }}
      scroll={{ x: "max-content" }}
      pageChangeWithRequest={true}
      {...props}
      request={(req) => request(req)}
      size="middle"
      search={
        props.searchFlag && {
          ...props.search,
          collapsed: true,
          afterSearch: afterSearchHandle,
          widgets: { QuerySelect, InputAndSelect, XZDateRange, XZInput, XZCascader },
        }
      }
      pagination={{ showSizeChanger: true }}
      toolbarRender={
        <>
          {props.toolbarRender}
          {/* <SetColumns></SetColumns> */}
          <XZExport
            requestParams={JSON.stringify({
              ...JSON.parse(sessionStorage.getItem(saveKey()) || "{}"),
            })}
            saveKey={saveKey()}
            configInfo={downConfig}
            fileName={props?.title ? trim((props.title as string)?.split("/")[0]) : "列表"}
          />
        </>
      }
    />
  )
}

export default forwardRef(SKTable)
